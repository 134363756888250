import React, {Component} from 'react'

import {Layout, Dropdown, Menu, Avatar, Tooltip} from 'antd';
import { withRouter} from 'react-router-dom'
import {Aside} from '../../components'
import {loginOutRequest,getUserDetail} from '../../request'
import {envHttpUrl} from '../../http/globalMethod'

import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    DownOutlined,
    LogoutOutlined,
    DeploymentUnitOutlined,
    UserOutlined
} from '@ant-design/icons';
import * as Icon from "@ant-design/icons";
const { Header, Content } = Layout;

class Frame extends Component {
    state = {
        collapsed: false,
    };
    toggle = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    };
    componentDidMount() {

    }
    render() {
        return (
            <Layout>
                <Header className="site-layout-background header-section" >
                    <div className="logo-wrap">
                        <div className="logo">{this.props.title}</div>
                        {React.createElement(this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                            className: 'trigger',
                            onClick: this.toggle,
                        })}
                    </div>
                </Header>
                <Layout className="site-layout">
                    <Aside sys={this.props.sys} sysLink={this.props.sysLink} collapsed={this.state.collapsed}/>
                    <Content
                        className="site-layout-background"
                        style={{
                            margin: '12px',
                            minHeight: 280,
                            overflowY:'scroll'
                        }}>
                        {this.props.children}

                    </Content>
                </Layout>
            </Layout>
        )
    }
}
export default withRouter(Frame)