import React, {Component} from 'react'
import {Input, Card, Row, Col, message, Modal, Divider, Tag, Avatar, Button, PageHeader} from 'antd';
import { StarFilled,ArrowRightOutlined } from '@ant-design/icons';
import {getBaseSystem,lcBiSystemLogin,lcBiSystemLoginSubmit,getMyCollectData} from '../../request'
import IconFun from '../../public/icon'
import './index.less'
export default class Portal extends Component {
    state = {
        systemData:[],
        collectData:[],
        isModalOpen:false,
        titleMessage:'请设置密码',
        password:'',
        logoImg:'http://lechunbucket.oss-cn-beijing.aliyuncs.com/kboneMiniResource/public/lechun-logo.jpg',

    }
    getSystemData = ()=>{
        getBaseSystem().then(res=>{
            console.log(res)
            this.setState({
                systemData:res||[]
            })
        })
    }
    getCollectData = ()=>{
        getMyCollectData().then(res=>{
            console.log(res)
            this.setState({
                collectData:res.list||[]
            })
        })
    }
    toSystemPage = (item)=>{
        console.log(item)
        let resourceHtml = window.location.protocol+'//'+item.resourceHtmlUrl.split('//')[1]
        let routeParam = {
            pathname:'/portal/lcpagesystem',
            search:'url='+encodeURIComponent(resourceHtml)
        }
        if(item.serviceId=='LECHUN-BI'){
            lcBiSystemLogin().then(res=>{
                console.log(res)
                if(res.success==0){
                    this.setState({
                        isModalOpen:true,
                        titleMessage:res.message
                    })
                }else{
                    this.props.history.push(routeParam)
                }
            })
        }else if(item.serviceId=='GUANYUAN-BI'){
            this.props.history.push('/portal/guanyuanbi')
        }else if(item.serviceId=='LECHUN-AMS'){
            this.props.history.push('/admin')
        }else if(item.serviceId=='LECHUN-OA'){
            this.props.history.push('/oa')
        }else{
            this.props.history.push(routeParam)

        }

    }
    handleLcBiSubmit(){
        console.log(this.state.password)
        lcBiSystemLoginSubmit({password:this.state.password}).then(res=>{
            message.success('成功')
            this.setState({
                isModalOpen:false,
            })
            let biUrl = 'http://bi.lechun.cc'
            // window.location.href = biUrl
            window.open(biUrl,'_blank')
        })
    }
    handleLcBiCancel(){
        this.setState({
            isModalOpen:false,
        })
    }
    handleInputPassword(e){
        console.log(e.currentTarget.value,this)
        this.setState({
            password:e.currentTarget.value
        })
    }
    componentDidMount(){
        this.getSystemData()
        this.getCollectData()
    }
    render() {
        return (
            <>
                <PageHeader
                    className="site-page-header"
                    title="乐纯管理系统"
                    subTitle=""
                />
                <div style={{padding:'20px 28px',boxSizing:'border'}} className='portal-card'>
                    <Row gutter={[28,28]}>
                        {this.state.systemData.map(item=>{
                            let icon = item.resourceIcon?item.resourceIcon:''
                            return (
                                <Col key={item.resourceId} xs={{ span: 24}} sm={{ span: 12}} md={{span:8}} lg={{span:6}}>
                                    <Card hoverable={true} key={item.resourceId} onClick={()=>this.toSystemPage(item)}>
                                        <IconFun icon={icon} className='sys-icon'/>
                                        <h2>{item.resourceName}</h2>
                                        <div>

                                        </div>
                                        <Tag color='pink'>{item.serviceId}</Tag>
                                        <Button size='small' shape='circle' icon={<ArrowRightOutlined/>}></Button>
                                    </Card>
                                </Col>

                            )
                        })}
                    </Row>
                    <div style={{marginTop:'20px'}}>
                        <h4><StarFilled /> 常用页面收藏</h4>
                        <Divider style={{margin:'10px 0 20px'}}></Divider>
                        <div style={{display:"flex",flexWrap:'wrap'}}>
                            {
                                this.state.collectData.map(item=>{
                                    return(
                                        <div style={{margin:'10px'}} onClick={()=>{
                                            window.open(item.pagePath,'_blank')
                                        }}>
                                            <Avatar shape="square" size={20} src={this.state.logoImg} />
                                            <span style={{cursor:'pointer',marginLeft:'8px',color:'#1890ff'}}>{item.pageTitle}</span>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>

                </div>
                <Modal
                    width="570px"
                    destroyOnClose={true}
                    title={this.state.titleMessage}
                    open={this.state.isModalOpen} onOk={()=>this.handleLcBiSubmit()} onCancel={this.handleLcBiCancel}>
                    <Input.Password placeholder="请输入密码" onChange={(e)=>this.handleInputPassword(e)} />
                </Modal>
            </>

        )
    }
}
