import React, {Component} from 'react'
import {
    Card,
    Table,
    Button,
    message,
    Tag, Tooltip,
    DatePicker, Pagination, Form, Popover, Select, Modal, Input, Dropdown, Alert
} from 'antd';
import moment from "moment";
import {
    getScoreResult360List,
    resetUserSurveyEndTime,
    resetSurveyEndTime,
    computeScore360,
    setConfirmScore360,
    getUserListForDropbox, computeAllScore360, setAll360EndTime, getEvalPeriodList, clearPerformanceSuperLeader
} from '../../../request'
import * as Icon from '@ant-design/icons';
import {withRouter} from "react-router-dom";
let columnsKey = {
    index:'序号',
    periodName: '期次',
    userNick:'被评人',
    orginScore:'得分',
    endTime:'结束时间',
    status:'状态',
    actions: '操作',
}
let subsColumnsKey = {
    evalUserNick: '评分人',
    refType:'评分人类型',
    evalClassName:'分类类型',
    projectName:'问卷信息',
    score:'分数',
    endTime:'结束时间',
    status:'状态',
    actions: '操作',
}
class ScoreResult360 extends Component {
    formRef = React.createRef()
    state = {
        dataSource:[],
        folderList:[],
        userList:[],
        periodList:[],
        columns:[],
        subsColumns:[],
        loading:false,
        visible:false,
        systemItem:{},
        formParam:{
            pageNum:1,
            pageSize:20,
        },
        formSetEndTimeParam:{},
        total:0,
        formDataParam:{
            name:''
        },
        setEndTimeDialogVisible:false,
        batchItems:[
            {
                label:<span onClick={()=>this.handleCountAll()}>批量计算分数</span>,
                key: '1',
            },
            {
                type: 'divider',
            },
            {
                label: <span onClick={()=>this.handleSetEndTime()}>批量修改结束时间</span>,
                key: '2',
            },
        ]
    };
    createColumns = (createKeys)=>{
        let columns = Object.keys(createKeys).map(item=>{
            let initObj = {
                title:createKeys[item],
                dataIndex:item,
                key:item,
                align:'left'
            }
            if(item=='index'){
                return {
                    ...initObj,
                    align:'center',
                    width:50,
                    render:(text,record,index)=>{
                        return index+1
                    }
                }
            }else if(item=='status'){
                return {
                    ...initObj,
                    width: 60,
                    render:(text,record,index)=>{
                        return(
                            text==2?
                            <Tag color="green">已出KPI</Tag>
                                :
                                (text==1?<Tag color="cyan">已计算</Tag>:<Tag color="red">未计算</Tag>)
                        )
                    }
                }
            }else if(item=='orginScore'){
                return {
                    ...initObj,
                    width: 80,
                    render: (text, record, index) => {
                        return (
                            <span>{text != undefined ? Number(text).toFixed(2) : '-'}</span>
                        )
                    }
                }
            }else if(item=='endTime'){
                return {
                    ...initObj,
                    width: 220,
                    render: (text, record, index) => {
                        return (
                            <>
                                {text}
                                {record.status != 2 ?
                                    <Popover content={
                                            <>
                                                <DatePicker format={'YYYY-MM-DD HH:mm:ss'} showTime
                                                            defaultValue={record.endTime ? moment(record.endTime, 'YYYY-MM-DD HH:mm:ss') : ''}
                                                            onChange={(val) => this.endTimeChange(val)}
                                                            onOk={(val) => this.resetEndTime(val, record, 'parent')}
                                                />
                                            </>
                                        } title="重置结束时间">
                                            <Button type="link" size="small">重置</Button>
                                        </Popover>: ''}
                            </>

                        )
                    }
                }
            }else if(item=='actions'){
                return {
                    ...initObj,
                    fixed: 'right',
                    width: 100,
                    render: (text, record, index) => {
                        return (
                            <>
                                {
                                        <>
                                            <Button type="link" size="small"
                                                    onClick={() => (this.computeScore(record))}>{record.status==0?'计算分':(record.status==1?'重新计算':'')}</Button>

                                            {/*{*/}
                                            {/*    record.status==1?<Popconfirm*/}
                                            {/*        title="360环评确认后，无法再重新计算分数，是否继续？"*/}
                                            {/*        onConfirm={()=>this.confirmScore(record)}*/}
                                            {/*        okText="确认"*/}
                                            {/*        cancelText="取消"*/}
                                            {/*    >*/}
                                            {/*        <Button type="link" size="small">确认分数</Button>*/}
                                            {/*    </Popconfirm>:''*/}
                                            {/*}*/}


                                        </>

                                }
                            </>
                        )
                    }
                }
            }else{
                return initObj
            }
        })
        this.setState({
            columns,
        })
    }
    createSubsColumns = (createKeys)=>{
        let columns = Object.keys(createKeys).map(item=>{
            let initObj = {
                title:createKeys[item],
                dataIndex:item,
                key:item,
                align:'left'
            }
            if(item=='status'){
                return {
                    ...initObj,
                    width: 60,
                    render: (text, record, index) => {
                        return (
                            text == 3 ?
                                <Tag color="purple">已汇算</Tag>
                                :
                                (text == 2 ? <Tag color="geekblue">已评分</Tag>
                                    :
                                    (text == 1 ? <Tag color="lime">已下发</Tag>
                                        : (text == -1 ? <Tooltip title={'360分类管理中设置中【自评是否计分】为【否】时，显示该信息'} color={'red'}><Tag
                                            color="red">自评无效 <Icon.InfoCircleOutlined /></Tag></Tooltip> : <Tag color="pink">已结束</Tag>)))
                        )
                    }
                }
            }else if(item=='refType'){
                return {
                    ...initObj,
                    render:(text,record,index)=>{
                        return(
                            text==1? 'peers' : (text==2?'leader' : (text==3?'下级':'自己'))
                        )
                    }
                }
            }else if(item=='endTime'){
                return {
                    ...initObj,
                    width: 220,
                    render:(text, record, index)=> {
                        return (
                            <>
                                {text}
                                {record.parentStatus != 2 ?
                                    <Popover content={
                                        <>
                                            <DatePicker format={'YYYY-MM-DD HH:mm:ss'} showTime
                                                        defaultValue={moment(record.endTime, 'YYYY-MM-DD HH:mm:ss')}
                                                        onChange={(val) => this.endTimeChange(val)}
                                                        onOk={(val) => this.resetEndTime(val, record)}
                                            />
                                        </>
                                    } title="重置结束时间">
                                        <Button type="link" size="small">重置</Button>
                                    </Popover> : ''
                                }

                            </>
                        )
                    }
                }
            }else if(item=='projectName'){
                return {
                    ...initObj,
                    render:(text,record,index)=>{
                        return(
                            <>
                                {text}
                                <span onClick={()=>this.copyTestCode(record.projectUrl)} style={{cursor:'pointer',fontSize:'10px',color:'green'}}>(<Icon.CopyOutlined />复制链接)</span>

                             </>
                        )
                    }
                }
            }else if(item=='score'){
                return {
                    ...initObj,
                    width: 80,
                    render: (text, record, index) => {
                        return (
                            <span>{text != undefined ? Number(text).toFixed(2) : '-'}</span>
                        )
                    }
                }
            }else if(item=='actions'){
                return {
                    ...initObj,
                    fixed: 'right',
                    width: 100,
                    render: (text, record, index) => {
                        return (
                            <>

                            </>
                        )
                    }
                }
            }else{
                return initObj
            }
        })
        this.setState({
            subsColumns:columns,
        })
    }
    initTableDataList(){
        this.setState({ loading: true });
        getScoreResult360List(this.state.formParam).then(res=>{
            this.setState({
                loading: false,
                dataSource:res.list,
                total:res.total
            })
        })
    }
    initUserList = ()=>{
        getUserListForDropbox({type:0}).
        then(res=>{
            this.setState({
                userList:[...res]
            })
        })
    }
    initEvalPeriodList = ()=>{
        getEvalPeriodList({type:0}).
        then(res=>{
            this.setState({
                periodList:[...res]
            })
        })
    }
    copyTestCode = (text)=>{
        var input = document.createElement("textarea"); // 创建input对象
        input.value = text; // 设置复制内容
        document.body.appendChild(input); // 添加临时实例
        input.select(); // 选择实例内容
        document.execCommand("Copy"); // 执行复制
        document.body.removeChild(input); // 删除临时实例
        message.success('已复制到剪贴板');
    }
    computeScore = (values)=>{
        computeScore360({userId:values.userId,periodId:values.periodId}).then(res=>{
            message.success('成功')
            this.initTableDataList()
        })
    }
    endTimeChange = (val)=>{
        console.log(val)
    }
    confirmScore = (val)=>{
        setConfirmScore360({id:val.id}).then(res=>{
            message.success('确认成功')
            this.initTableDataList()
        })
    }
    handleCountAll = ()=>{
        computeAllScore360({}).then(res=>{
            message.success(res||'计算成功')
            this.initTableDataList()
        })
    }
    handleSetEndTime = ()=>{
        // Modal.confirm({
        //     title:'提示',
        //     content:'必须选择查询条件，只有未打分的才会被修改结束时间，确定要批量设置结束时间吗？',
        //     onOk:()=>{
        //         setAll360EndTime({}).then(res=>{
        //             message.success(res||'操作成功')
        //             this.initTableDataList()
        //         })
        //     },
        //     onCancel:()=>{
        //
        //     }
        //
        // })
        this.setState({
            setEndTimeDialogVisible:true
        })

    }
    handleSubmitTime = (val)=>{
        let {pageSize,pageNum,...otherParam} = this.state.formParam
        let param = {
            endTime:val.endTime?moment(val.endTime).format('YYYY-MM-DD HH:mm:ss'):null,
        }
        console.log(val,otherParam,param)
        setAll360EndTime({...otherParam,...param}).then(res=>{
            message.success(res||'操作成功',5)
            this.handleCloseModal()
            this.initTableDataList()
        })
    }
    handleCloseModal = ()=>{
        this.formRef.current.resetFields()
        this.setState({
            setEndTimeDialogVisible:false,
            formSetEndTimeParam:{}
        })
    }
    resetEndTime = (val,record,type)=>{
        let editTime = moment(val).format('YYYY-MM-DD HH:mm:ss')
        if(type=='parent'){
            resetUserSurveyEndTime({id:record.id,endTime:editTime}).then(res=>{
                message.success('成功')
                this.initTableDataList()
            })
        }else{
            resetSurveyEndTime({id:record.id,endTime:editTime}).then(res=>{
                message.success('成功')
                this.initTableDataList()
            })
        }
    }
    expandedRowRender = (record)=>{
        let subData = record.projectDistributeList.map(item=>{
            item.parentStatus = record.status
            return item
        })
        return <Table pagination={false} columns={this.state.subsColumns} dataSource={subData} />
    }
    handleValueChange = (values,allValues)=>{
        let param = this.state.formParam
        param.pageNum = 1
        this.setState({
            formParam:{
                ...param,...values
            }
        },()=>{
            this.initTableDataList()

        })
    }
    componentDidMount(){
        this.createColumns(columnsKey);
        this.createSubsColumns(subsColumnsKey);
        this.initUserList()
        this.initEvalPeriodList()
        this.initTableDataList()
    }
    render() {
        const { dataSource,columns, loading,setEndTimeDialogVisible,systemItem,formParam} = this.state;

        return (
            <>
                <Card title={<span>360打分汇总</span>} bordered={false} className={'card-fixed-head-wrap'}>
                    <div style={{display:"flex",justifyContent:'space-between',marginBottom:'20px'}}>
                        <Form layout="inline"
                              initialValues={formParam}
                              onValuesChange={this.handleValueChange}>
                            <Form.Item
                                name="status"
                            >
                                <Select
                                    style={{minWidth:'200px'}}
                                    allowClear
                                    showSearch
                                    placeholder="状态"
                                    filterOption={(input, option) =>
                                        (option?.name ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={[
                                        {label:'所有',value:''},
                                        {label:'未计算',value:0},
                                        {label:'已计算',value:1},
                                        {label:'已出KPI',value:2},
                                    ]}
                                />
                            </Form.Item>
                            <Form.Item name={'userId'}>
                                <Select
                                    style={{minWidth: '200px'}}
                                    showSearch
                                    optionFilterProp="children"
                                    fieldNames={{
                                        label: 'USER_NICK',
                                        value: 'USER_ID'
                                    }}
                                    filterOption={(input, option) => {
                                        let searchText = (option?.USER_NICK ?? '') + (option?.pinyin ?? '')
                                        return searchText.toLowerCase().includes(input.toLowerCase())
                                    }
                                    }
                                    allowClear
                                    placeholder="请选择被评人"
                                    options={this.state.userList}
                                />
                            </Form.Item>
                            <Form.Item name={'periodId'}>
                                <Select
                                    style={{minWidth: '200px'}}
                                    showSearch
                                    optionFilterProp="children"
                                    fieldNames={{
                                        label: 'periodName',
                                        value: 'periodId'
                                    }}
                                    filterOption={(input, option) => {
                                        let searchText = (option?.periodName ?? '') + (option?.pinyin ?? '')
                                        return searchText.toLowerCase().includes(input.toLowerCase())
                                    }
                                    }
                                    allowClear
                                    placeholder="请选择期次"
                                    options={this.state.periodList}
                                />
                            </Form.Item>
                            <Form.Item>
                                <Tooltip title={
                                    <>
                                        批量修改结束时间：必须选择查询条件，只有未打分的才会被修改结束时间
                                    </>
                                }>
                                    <Button type={'primary'} onClick={()=>this.handleSetEndTime()}><Icon.InfoCircleOutlined/> 批量修改结束时间</Button>
                                </Tooltip>
                            </Form.Item>
                            <Form.Item>
                                <Tooltip title={
                                    <>
                                        批量计算分数：计算所有非【已出KPI】状态的所有问卷分数
                                    </>
                                }>
                                    <Button type={'primary'} danger onClick={()=>this.handleCountAll()}><Icon.InfoCircleOutlined/> 批量计算分数</Button>
                                </Tooltip>
                            </Form.Item>
                        </Form>
                    </div>
                    <Alert message="总分or分数 需要在点击【计算分】或【重新计算】按钮后才会计算并展示。分数确认会由后台自动确认，无需手动；" type="info" showIcon />
                    <Table
                        bordered
                        size="small"
                        pagination={false}
                        dataSource={dataSource}
                        columns={columns}
                        rowKey={record=>record.id}
                        loading={loading}
                        expandable={{
                            expandedRowRender:this.expandedRowRender
                        }}
                    />
                    <div style={{display:'flex',justifyContent:'flex-end',margin:'20px 0'}}>
                        <Pagination size="small"
                                    hideOnSinglePage={true}
                                    current={this.state.formParam.pageNum}
                                    pageSize={this.state.formParam.pageSize}
                                    total={this.state.total}
                                    showSizeChanger
                                    onChange={(page, pageSize)=>{
                                        let param = this.state.formParam
                                        param.pageSize = pageSize
                                        param.pageNum = page
                                        this.setState({
                                            formParam:{...param}
                                        })
                                        this.initTableDataList()
                                    }}
                        />

                    </div>
                </Card>
                <Modal destroyOnClose forceRender title={"批量修改结束时间"} open={setEndTimeDialogVisible} footer={false} onCancel={this.handleCloseModal}>
                    <Form
                        ref={this.formRef}
                        layout="horizontal"
                        labelCol={{span:4}}
                        onValuesChange={this.handleTimeValueChange}
                        onFinish={this.handleSubmitTime}
                    >
                        <Alert message="批量修改结束时间:必须选择查询条件，只有未打分的才会被修改结束时间" type="warning" showIcon />
                        <Form.Item name={'endTime'}
                                   label={'结束时间'}
                                   required={true}
                        >
                            <DatePicker style={{width:'100%'}} valueFormat={'YYYY-MM-DD'} format={'YYYY-MM-DD HH:mm:ss'} showTime/>
                        </Form.Item>
                        <div style={{display:'flex',justifyContent:'flex-end',alignItems:'center'}}>
                            <Button type="primary" htmlType="submit">
                                提交
                            </Button>
                        </div>
                    </Form>
                </Modal>

            </>

        )
    }
}
export default withRouter(ScoreResult360)