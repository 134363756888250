import React, {Component, useState} from 'react'
import {
    Card,
    Table,
    Button,
    message, Descriptions,
    Tag, Divider,
    Drawer,Tooltip,List,
    Row, Col, Form, Badge, Select, Modal, Input, Collapse, PageHeader, InputNumber, Empty, Spin, Space, Alert
} from 'antd';
import {
    getPerformanceEvaList,
    savePerformanceEva,
    appointSuperLeader,
    getUserListForDropbox,
    clearLeaderPerformanceRate,clearSelfPerformanceRate, clearPerformanceSuperLeader
} from '../../request'
import html2canvas from 'html2canvas';
import * as Icon from '@ant-design/icons';
import ProcessSteps from "./processSteps"
import {withRouter} from "react-router-dom";
const { Panel } = Collapse;
import IconFont from '../../public/iconfont/iconFont'

import './performance.less'
class PerformanceDetail extends Component {
    performanceRef = React.createRef()
    surveyModal = React.createRef()
    formRef = {}
    state = {
        dataSource:[],
        dicList:{},
        SurveyQaList:[],
        columns:[],
        loading:false,
        visible:false,
        systemItem:{},
        formParam:{
            userId:this.props.id!=0?this.props.id:null,
        },
        total:0,
        formDataParam:{
            name:''
        },
        detailDrawerVisible:false,
        submitParam:{},
        userName:'',
        userList:[],
        superLeaderId:undefined,
        downloadLoading:false
    };
    initTableDataList(){
        this.setState({ loading: true });
        console.log(this.state.formParam)
        getPerformanceEvaList(this.state.formParam).then(res=>{
            // this.formRef['formRef2'].setFieldsValue({})
            this.setState({
                loading: false,
                dataSource:res.list,
                userName:res.currentUserName||''
            })
            console.log('f',this.formRef)
            // this.formRef['formRef1'].resetFields()
        })
    }
    initUserList = ()=>{
        getUserListForDropbox({type:0}).
        then(res=>{
            this.setState({
                userList:[...res]
            })
        })
    }
    handlePushFilterValueChange = (changedValues, allValues, index)=>{

        // let param = this.state.dataSource
        // param[index].content = allValues.content
        console.log('change',index,changedValues, allValues)
        this.setState({
            submitParam:allValues
        })
        // this.saveValues(0)
    }
    handleBlur = (val,index,from)=>{
        console.log(val,this.state.submitParam)
        this.saveValues(0,index,from)
    }
    saveValues = (state,index,from)=>{
        let param = this.state.dataSource[index]
        let setParam = {...param,...this.state.submitParam}
        savePerformanceEva({...setParam,publish:state}).then(res=>{
            this.updateDataSource(res,index)
            //leaderRateSum
            // :
            // 11.31
            // selfRateSum
            // :
            // 6.51
            // superLeaderRateSum
            // :
            // 27.64
            // total4RateSum
            // :
            // 45.47
            // totalRateSum
            // :
            // 45.47
            message.success(state==1?'保存成功':'已存草稿')
            if(from=='score'||state==1){
                let content = <div style={{textAlign:'left'}}>
                    自评分数：{res.selfRateSum}<br/>
                    leader评分：{res.leaderRateSum}<br/>
                    部门leader评分：{res.superLeaderRateSum}<br/>
                    总分(不包括360评分)：{res.total4RateSum}<br/>
                    最终总分：{res.totalRateSum}
                </div>
                message.success(content,5)
            }
            if(state==1){
                this.setState({
                    submitParam:{}
                })
                this.initTableDataList()

            }

        })
    }
    downloadValues = async(item)=>{
        await this.setState({
            downloadLoading:true
        })
        this.downloadPDF('performance'+item.periodId,this.state.userName+': '+item.periodHeadString)
    }
    downloadPDF = (id,name) => {

        const input = document.getElementById(id); // 假设你要转换的元素有id="content"
        html2canvas(input, { scale: 1 }).then(canvas => {
            let img = canvas.toDataURL("image/jpeg");
            let a = document.createElement('a');
            // 设置下载属性
            a.href = img;
            a.download = name+'.png';

            // 触发下载
            a.click();
            this.setState({
                downloadLoading:false,
            })



        });
    }
    clearLeaderScore = (item,index)=>{
        let modalTitle = '确定要清空leader评分吗？清空leader评分同时也会清除「部门leader评分」，请谨慎选择！'
        Modal.confirm({
            title:'提示',
            content:modalTitle,
            onOk:()=>{
                console.log('formf',this.formRef['formRef'+index])
                clearLeaderPerformanceRate({ userId:item.userId,periodId:item.periodId}).then(res=>{
                    message.success('清除成功')
                    this.formRef['formRef'+index].resetFields()
                    this.initTableDataList()
                    this.setState({
                        submitParam:{},
                    })

                })
            },
            onCancel:()=>{

            }

        })

    }
    clearLeader = (item,index)=>{
        let modalTitle = '确定要删除部门leader？'
        Modal.confirm({
            title:'提示',
            content:modalTitle,
            onOk:()=>{
                console.log('formf',this.formRef['formRef'+index])
                clearPerformanceSuperLeader({ userId:item.userId,periodId:item.periodId}).then(res=>{
                    message.success('清除成功')
                    this.formRef['formRef'+index].resetFields()
                    this.initTableDataList()
                    this.setState({
                        submitParam:{}
                    })

                    console.log('set',this.state.dataSource[index])



                })
            },
            onCancel:()=>{

            }

        })

    }

    initTableColumns = (listRecord,listIndex)=>{
        let publicCol = {
            selfRate:<span>员工自评<br/>({listRecord.selfRatePercent})</span>,
            leaderRate:<span>直属leader<br/>({listRecord.leaderRatePercent})</span>,
            superLeaderRate:<span>部门leader<br/>{listRecord.superLeaderName?<>{listRecord.superLeaderName}<br/></>:''}({listRecord.superLeaderRatePercent?listRecord.superLeaderRatePercent:'0%'})</span>
        }
        let col1 = {
            project:'考核项目1',
            content:'考核内容',
            result:<span>实际达成情况<br/>（避免概述，须有案例或数据支撑）</span>,
            standard:'评价标准'
        }
        let col2 = {
            project:'考核项目2',
            type:'考核维度',
            selfContent:'参考评价标准要求员工填写相应内容',
            standard:'评价标准'
        }
        let col3 = {
            project:'考核项目3',
            person:'评估人',
            suggest:'定性建议',
            score:'定量得分'
        }
        let col1Set = {...col1,...publicCol}
        let column1 = Object.keys(col1Set).map(item=> {
            let initObj = {
                title: col1Set[item],
                dataIndex: item,
                key: item,
                align: 'center'
            }
            if(item=='project'){
                return {
                    ...initObj,
                    width:'100px'
                }
            }else if(item=='content'){
                return {
                    ...initObj,
                    width:'220px'
                }
            }else if(item=='standard'){
                return {
                    ...initObj,
                    align:'left',
                    render:(text, record, index)=>{
                        let list = text.split('\n')
                        let scoreLevel = {
                            0:'red',
                            1:'pink',
                            2:'gold',
                            3:'blue',
                            4:'green',

                        }
                        return (
                            <>
                                {
                                    list.map((item1,index1)=>{
                                        return <div key={index1}>
                                            <Badge status={scoreLevel[index1]} text={
                                                <span style={{fontSize:'12px'}}>{item1}</span>
                                            } />
                                            <Divider style={{margin:'3px 0'}} dashed />
                                        </div>
                                    })
                                }
                            </>
                        )
                    }
                }
            }else if(item=='selfRate'){
                return {
                    ...initObj,
                    width:80,
                    render:(text, record, index)=>{
                        return (
                            <>
                                <Form.Item hidden name={'periodId'}></Form.Item>
                                <Form.Item hidden name={'userId'}></Form.Item>
                                <Form.Item name={'kpiSelfRate'}>
                                    <InputNumber onBlur={(val)=>this.handleBlur(val,listIndex,'score')} disabled={!listRecord.selfEdit} style={{width:'100%'}} min={0} max={listRecord.kpiFullScore} defaultValue={0} />
                                </Form.Item>
                            </>
                        )
                    }
                }
            }else if(item=='leaderRate'){
                return {
                    ...initObj,
                    width:80,
                    render:(text, record, index)=>{
                        return (
                            <>
                                <Form.Item name={'kpiLeaderRate'}>
                                    <InputNumber onBlur={(val)=>this.handleBlur(val,listIndex,'score')} disabled={!listRecord.leaderEdit} style={{width:'100%'}} min={0} max={listRecord.kpiFullScore} defaultValue={0} />
                                </Form.Item>
                            </>
                        )
                    }
                }
            }else if(item=='superLeaderRate'){
                return {
                    ...initObj,
                    width:80,
                    render:(text, record, index)=>{
                        return (
                            <>
                                <Form.Item name={'kpiSuperLeaderRate'}>
                                    <InputNumber onBlur={(val)=>this.handleBlur(val,listIndex,'score')} disabled={listRecord.superLeaderShow==1&&listRecord.superLeaderEdit?false:true} style={{width:'100%'}} min={0} max={listRecord.kpiFullScore} defaultValue={0} />
                                </Form.Item>
                            </>
                        )
                    }
                }
            }else if(item=='result'){
                return {
                    ...initObj,
                    width:'280px',
                    render:(text, record, index)=>{
                        return (
                            <>
                                {
                                    this.state.downloadLoading?
                                        <div className={!listRecord.selfEdit?'text-area-div disabled':'text-area-div'} dangerouslySetInnerHTML={{ __html: (record.result||'').replace(/(\n|\r|\r\n)/g, '<br />') }} /> // 核心代码 drawDescribe.replace(/(\n|\r|\r\n)/g, '<br />')
                                        :
                                        <Form.Item name={'kpiSelfContent'}>
                                            <Input.TextArea onBlur={(val)=>this.handleBlur(val,listIndex)} autoSize disabled={!listRecord.selfEdit} />
                                        </Form.Item>
                                }
                            </>
                        )
                    }
                }
            }else{
                return initObj
            }

        })
        let col2Set = {...col2,...publicCol}
        let column2 = Object.keys(col2Set).map(item=> {
            let initObj = {
                title: col2Set[item],
                dataIndex: item,
                key: item,
                align: 'center'
            }
            if(item=='project'){
                return {
                    ...initObj,
                    width:'100px',
                    onCell:(_,index)=>{
                        if(index===0){
                            return {rowSpan:7}
                        }else{
                            return {rowSpan:0}
                        }},
                    render:()=>{
                        return listRecord.valuesTypeString
                    }
                }
            }else if(item=='type'){
                return {
                    ...initObj,
                    width:'220px',
                    render:(text,record,index)=>{
                        return record.record.type
                    }
                }
            }else if(item=='selfContent'){

                return {
                    ...initObj,
                    width:'280px',
                    render:(text, record, index)=> {
                        return (
                            <>
                                <Form.Item hidden name={[record.name, 'id']}>
                                </Form.Item>
                                {
                                    this.state.downloadLoading?
                                        <div className={!listRecord.selfEdit?'text-area-div disabled':'text-area-div'} dangerouslySetInnerHTML={{ __html: (record.record.selfContent||'').replace(/(\n|\r|\r\n)/g, '<br />') }} /> // 核心代码 drawDescribe.replace(/(\n|\r|\r\n)/g, '<br />')
                                        :
                                        <Form.Item name={[record.name, 'selfContent']}>
                                            <Input.TextArea className={'text-area-div'} style={{whiteSpace: 'pre-line'}}
                                                            onBlur={(val) => {
                                                                this.handleBlur(val, listIndex)
                                                            }} autoSize disabled={!listRecord.selfEdit}/>
                                        </Form.Item>
                                }

                            </>

                        )
                    }
                }
            }else if(item=='standard'){
                return {
                    ...initObj,
                    align:'left',
                    render:(text, record, index)=>{
                        let info = record.record.standard
                        let list = info?info.split('\n'):[]
                        let scoreLevel = {
                            0:'red',
                            1:'pink',
                            2:'gold',
                            3:'blue',
                            4:'green',
                        }
                        return (
                            <div>
                                {
                                    list.map((item1,index1)=>{
                                        return <div key={index1}>
                                            <Badge status={scoreLevel[index1]} text={
                                                <span style={{fontSize:'12px'}}>{item1}</span>
                                            } />
                                            <Divider style={{margin:'3px 0'}} dashed />
                                        </div>
                                    })
                                }
                            </div>
                        )
                    }
                }
            }else if(item=='selfRate'){
                return {
                    ...initObj,
                    width: 80,
                    render: (text, record, index) => {
                        return (
                            <>
                                <Form.Item name={[record.name,'selfRate']}>

                                    <InputNumber onBlur={(val)=>this.handleBlur(val,listIndex,'score')} disabled={!listRecord.selfEdit} style={{width: '100%'}} step={0.5} min={0}
                                                 max={record.record.valuesFullScore} defaultValue={0}/>
                                </Form.Item>
                            </>
                        )
                    }
                }
            }else if(item=='leaderRate'){
                return {
                    ...initObj,
                    width:80,
                    render:(text, record, index)=>{
                        return (
                            <>
                                <Form.Item name={[record.name,'leaderRate']}>

                                    <InputNumber onBlur={(val)=>this.handleBlur(val,listIndex,'score')} disabled={!listRecord.leaderEdit} style={{width:'100%'}} step={0.5} min={0} max={record.record.valuesFullScore} defaultValue={0} />

                                </Form.Item>
                            </>
                        )
                    }
                }
            }else if(item=='superLeaderRate'){
                return {
                    ...initObj,
                    width:80,
                    render:(text, record, index)=>{
                        return (
                            <>
                                <Form.Item name={[record.name,'superLeaderRate']}>
                                    <InputNumber onBlur={(val)=>this.handleBlur(val,listIndex,'score')} disabled={listRecord.superLeaderShow==1&&listRecord.superLeaderEdit?false:true} style={{width:'100%'}} step={0.5} min={0} max={record.record.valuesFullScore} defaultValue={0} />
                                </Form.Item>
                            </>
                        )
                    }
                }
            }else{
                return {
                    ...initObj,
                    render:(text,record,index)=>{
                        {record[item]}
                    }
                }
            }

        })
        let cellM = (_,index)=>{
            if (index === 1) {
                return { colSpan: 0 };
            }
        }
        let column3 = Object.keys(col3).map(item=> {
            let initObj = {
                title: col3[item],
                dataIndex: item,
                key: item,
                align: 'center'
            }
            if(item=='project'){
                return {
                    ...initObj,
                    width:100
                }
            }else if(item=='person'){
                return {
                    ...initObj,

                    children:[
                        {title:'上级',dataIndex:'leader',width:120,onCell: (_, index) => {
                                if(index==1){
                                    return { colSpan: 4 }
                                }
                            },
                        },
                        {title:'协作伙伴',dataIndex:'peer',width:120,onCell:cellM},
                        {title:'下级伙伴',dataIndex:'down',width: 140,onCell:cellM},
                    ]
                }
            }else if(item=='suggest'){
                return {
                    ...initObj,
                    align: 'left',
                    onCell:cellM,
                    render:(text,record)=>{
                        let arr = text||[]
                        return (
                            <>
                                <List
                                    className={'eval-360-list'}
                                    dataSource={arr}
                                    renderItem={(item1)=>(
                                        <List.Item key={item1.title}>
                                            <List.Item.Meta
                                                title={item1.title}
                                                description={
                                                    <div>
                                                        {item1.answer.map((item2,index2)=>{
                                                            return <div key={index2}>
                                                                <Badge status="processing" />{item2}
                                                            </div>
                                                        })}
                                                    </div>
                                                }
                                            />
                                        </List.Item>
                                    )}
                                />

                            </>
                        )
                    }
                }
            }else if(item=='score'){
                return {
                    ...initObj,width: 240
                }
            }else{
                return initObj
            }

        })
        return {
            column1,column2,column3
        }
    }
    initTableDataSource = (record)=>{

        let dataSource1 = [
            {
                id:1,
                project:record.kpiTypeString,
                content:<p style={{whiteSpace:'pre-line'}}>{record.kpiContentString}</p>,
                standard:record.kpiEvaluateStandardString,
                result:record.kpiSelfContent,
                selfRate:record.kpiSelfRate,
                leaderRate:record.kpiLeaderRate,
                superLeaderRate:record.kpiSuperLeaderRate,
            }
        ]
        let dataSource2 = [
            ...record.valuesData
        ]
        let data360 = record.evalResultVo||{}
        let refUsers = data360.refUsers||{}
        let dataSource3 = [
            {
                project:<p style={{whiteSpace:'pre-line'}}>{data360.name}</p>,
                peer:refUsers['协作者']?refUsers['协作者'].join(','):'-',
                down:refUsers['下级']?refUsers['下级'].join(','):'-',
                leader:refUsers['领导']?refUsers['领导'].join(','):'-',
                suggest:data360.suggest,
                score:data360.score,
            },
        ]

        let dataSource4 = [
            {
                title: '【评分合计】考核项目1+2（公式：KPI+价值观七项平均分x5）',
                selfRateSum: record.selfRateSum,
                leaderRateSum: record.leaderRateSum,
                superLeaderRateSum: record.superLeaderRateSum,
            }
        ]
        let dataSource5 = [
            {
                title:'总分',
                subs:record.total3TypeString,
                score:record.totalRateSum
            },
        ]
        let dataSource6 = [
            {
                title:record.coolTitle||'完成情况',
                result:record.coolScoreResult,
                coolComplete:record.coolComplete
            },
        ]
        let dataSource7 = [
            {
                title:'最终总分',
                subs:record.total4TypeString,
                score:record.total4RateSum
            },
        ]
        return {
            dataSource1,dataSource2,dataSource3,dataSource4,dataSource5,dataSource6,dataSource7
        }
    }
    updateDataSource = (record,index)=>{
        let dataSource = this.state.dataSource
        dataSource[index] = {...this.state.dataSource[0],...record}
        this.setState(
            {
                dataSource: dataSource
            }
        )
        console.log(this.state.dataSource)
    }
    getPerformanceState = (item)=>{
        let stateObj = {
            1:{
                color:'red',
                icon:'icon-buhege'
            },
            2:{
                color:'#00bcd4',
                icon:'icon-hege1'
            },
            3:{
                color:'green',
                icon:'icon-youxiu6'
            }
        }
        return (
            <span style={{marginLeft:'10px'}}>
                {
                    stateObj[item.scoreGrade]?
                        <IconFont style={{position:'absolute',top:0,fontSize:'44px',color:stateObj[item.scoreGrade].color}} type={stateObj[item.scoreGrade].icon} />:''
                }

            </span>

        )
    }

    addSuperLeader = (item)=>{
        appointSuperLeader({
            userId:this.state.formParam.userId,
            periodId:item.periodId,
            superLeaderId:this.state.superLeaderId
        }).then(res=>{
            message.success('添加成功')
            this.initTableDataList()
        })
    }
    componentDidMount(){
        this.initUserList()
        this.initTableDataList()

    }
    render() {
        const locationQuery = new URLSearchParams(window.location.search)
        const periodId = locationQuery.get('periodId')||''
        const {dataSource, columns, loading, detailDrawerVisible, dicList, editParam} = this.state;
        return (
            <>
                <Card title={
                    this.props.id?
                        <>
                            <span style={{marginRight:'10px',fontWeight:'bolder',cursor:'pointer'}} onClick={()=>this.props.history.go(-1)}><Icon.ArrowLeftOutlined/></span>
                            {
                                '绩效评估：' + this.state.userName
                            }
                        </>:
                        '绩效评估：我的'
                } bordered={false}
                      className={'card-fixed-head-wrap performance-detail-wrap'}>
                    <Spin spinning={loading}>
                        {
                            this.state.dataSource.length > 0 ?
                                <Collapse defaultActiveKey={[periodId]} expandIconPosition={'end'}>
                                    {
                                        this.state.dataSource.map((item, index) => {
                                            let columnsAll = this.initTableColumns(item,index)
                                            let dataSourceAll = this.initTableDataSource(item)
                                            return (
                                                <Panel style={{position:"relative"}} forceRender collapsible={item.viewable ? 'header' : 'disabled'}
                                                       header={
                                                           <Tooltip title={'当前周期的进度为：'+item.stage} placement={'topLeft'}>

                                                               <div style={{paddingLeft:'90px'}}>
                                                                   <Badge.Ribbon placement={'start'} text={item.stage} color="#54a6f1" />
                                                                   {item.periodString}
                                                                   {
                                                                       this.getPerformanceState(item)
                                                                   }
                                                               </div>
                                                           </Tooltip>

                                                       }
                                                       extra={
                                                           <>
                                                               <ProcessSteps steps={item.stageVO}/>

                                                           </>

                                                       }
                                                       key={item.periodId}>

                                                    <Spin spinning={this.state.downloadLoading}>
                                                    <div style={{
                                                        marginBottom: '10px',
                                                        display: 'flex',
                                                        justifyContent: 'space-between'
                                                    }}>
                                                        <div>
                                                            <img src={this.state.img}/>
                                                            {
                                                                item.showSuperLeaderSelect ?
                                                                    <>

                                                                        <Select
                                                                            style={{minWidth: '200px'}}
                                                                            showSearch
                                                                            optionFilterProp="children"
                                                                            fieldNames={{
                                                                                label: 'USER_NICK',
                                                                                value: 'USER_ID'
                                                                            }}
                                                                            filterOption={(input, option) => {
                                                                                let searchText = (option?.USER_NICK ?? '') + (option?.pinyin ?? '')
                                                                                return searchText.toLowerCase().includes(input.toLowerCase())
                                                                            }
                                                                            }
                                                                            allowClear
                                                                            placeholder="请添加部门leader"
                                                                            options={this.state.userList}
                                                                            onChange={(value, option) => {
                                                                                this.setState({
                                                                                    superLeaderId: value
                                                                                })

                                                                            }}
                                                                        />
                                                                        <Button
                                                                            onClick={() => this.addSuperLeader(item)}
                                                                            danger type={'primary'}>添加</Button>
                                                                        <span style={{marginLeft:'10px'}}>
                                                                            <Tooltip title={'请您与【您的直属上级】确认TA是否需要对该员工的试用期考核进行评分，如果需要麻烦指派。'}>
                                                                                <span style={{color:'green'}}><Icon.InfoCircleOutlined/> 说明</span>
                                                                            </Tooltip>
                                                                        </span>

                                                                    </> : ''
                                                            }

                                                        </div>
                                                        <div>
                                                            <Space>
                                                                {item.showSave ?
                                                                    <Tag  style={{cursor: 'pointer'}}
                                                                          onClick={() => this.saveValues(1,index)}
                                                                          color={'cyan'}>发布</Tag>
                                                                    : ''}
                                                                {item.leaderEdit ?
                                                                    <Tag style={{cursor: 'pointer'}}
                                                                         onClick={() => this.clearLeaderScore(item,index)}
                                                                         color={'red'}>清空Leader评分</Tag>
                                                                    : ''}
                                                                {item.showSuperLeaderClear ?
                                                                    <Tag style={{cursor: 'pointer'}}
                                                                         onClick={() => this.clearLeader(item,index)}
                                                                         color={'pink'}>删除部门leader</Tag>
                                                                    : ''}

                                                                <Tag style={{cursor: 'pointer'}}
                                                                     onClick={() => this.downloadValues(item)}
                                                                     color={'green'}>导出</Tag>
                                                            </Space>


                                                        </div>
                                                    </div>
                                                    <Form id={'performance' + item.periodId}
                                                          ref={(el)=>this.formRef['formRef'+index] = el}
                                                          className={'performance-form'} initialValues={item}
                                                          onFieldsChange={(changedFields, allFields) => console.log('filedChnage', changedFields, allFields)}
                                                          onValuesChange={(changedValues, allValues) => this.handlePushFilterValueChange(changedValues, allValues, index)}
                                                    >
                                                        <Table
                                                            size={'small'}
                                                            bordered
                                                            pagination={false}
                                                            columns={
                                                                columnsAll.column1
                                                            }
                                                            dataSource={dataSourceAll.dataSource1}
                                                            rowKey={record=>record.id}
                                                        />
                                                        <Form.List name={'valuesData'} initialValue={item.valuesData}>
                                                            {(fields, operation) => {
                                                                let newFields = fields.map((field, index) => {
                                                                    field.record = item.valuesData[index]
                                                                    return field
                                                                })
                                                                return <Table
                                                                    size={'small'}
                                                                    bordered
                                                                    pagination={false}
                                                                    columns={
                                                                        columnsAll.column2
                                                                    }
                                                                    dataSource={newFields}
                                                                    rowKey={record=>record.id}
                                                                />
                                                            }}
                                                        </Form.List>

                                                        <Table
                                                            className={'table-score'}
                                                            showHeader={false}
                                                            size={'small'}
                                                            bordered
                                                            pagination={false}
                                                            columns={
                                                                [
                                                                    {dataIndex: 'title', title: '总分', align: 'center'},
                                                                    {
                                                                        dataIndex: 'selfRateSum',
                                                                        title: '得分',
                                                                        width: 80,
                                                                        align: 'center'
                                                                    },
                                                                    {
                                                                        dataIndex: 'leaderRateSum',
                                                                        title: '得分',
                                                                        width: 80,
                                                                        align: 'center'
                                                                    },
                                                                    {
                                                                        dataIndex: 'superLeaderRateSum',
                                                                        title: '得分',
                                                                        width: 80,
                                                                        align: 'center'
                                                                    },
                                                                ]
                                                            }
                                                            dataSource={
                                                                dataSourceAll.dataSource4
                                                            }
                                                        />
                                                        <Table
                                                            size={'small'}
                                                            bordered
                                                            pagination={false}
                                                            columns={
                                                                columnsAll.column3
                                                            }
                                                            dataSource={dataSourceAll.dataSource3}
                                                        />
                                                        <Table
                                                            className={'table-score'}
                                                            showHeader={false}
                                                            size={'small'}
                                                            bordered
                                                            pagination={false}
                                                            columns={
                                                                [
                                                                    {
                                                                        dataIndex: 'title',
                                                                        title: '总分',
                                                                        width: 100,
                                                                        align: 'center'
                                                                    },
                                                                    {dataIndex: 'subs', title: '描述'},
                                                                    {
                                                                        dataIndex: 'score',
                                                                        title: '得分',
                                                                        width: 240,
                                                                        align: 'center'
                                                                    },
                                                                ]
                                                            }
                                                            dataSource={
                                                                dataSourceAll.dataSource5
                                                            }
                                                        />
                                                        <Table
                                                            size={'small'}
                                                            bordered
                                                            pagination={false}
                                                            columns={
                                                                [
                                                                    {
                                                                        dataIndex: 'title',
                                                                        title: '培训模块',
                                                                        width: 100,
                                                                        align: 'center'
                                                                    },
                                                                    {
                                                                        dataIndex: 'result',
                                                                        title: '当月培训旅程',
                                                                        render: (text, record, index) => {
                                                                            return (
                                                                                <>
                                                                                    {
                                                                                        (text || []).map((item2,index2) => {
                                                                                            return <div key={index2}><Badge
                                                                                                status={'processing'}
                                                                                                text={item2}/></div>
                                                                                        })
                                                                                    }
                                                                                </>
                                                                            )
                                                                        }
                                                                    },
                                                                    {
                                                                        dataIndex: 'coolComplete',
                                                                        title: '培训得分',
                                                                        width: 240,
                                                                        align: 'center'
                                                                    },
                                                                ]
                                                            }
                                                            dataSource={dataSourceAll.dataSource6}
                                                        />
                                                        <Table
                                                            className={'table-score'}
                                                            showHeader={false}
                                                            size={'small'}
                                                            bordered
                                                            pagination={false}
                                                            columns={
                                                                [
                                                                    {
                                                                        dataIndex: 'title',
                                                                        title: '总分',
                                                                        width: 100,
                                                                        align: 'center'
                                                                    },
                                                                    {dataIndex: 'subs', title: '描述'},
                                                                    {
                                                                        dataIndex: 'score',
                                                                        title: '得分',
                                                                        width: 240,
                                                                        align: 'center',
                                                                        render: (text, record, index) => {
                                                                            return (
                                                                                <>
                                                                                    {text}
                                                                                    {this.getPerformanceState(item)}
                                                                                </>

                                                                            )
                                                                        }
                                                                    }
                                                                ]
                                                            }
                                                            dataSource={
                                                                dataSourceAll.dataSource7
                                                            }
                                                        />
                                                    </Form>
                                                    </Spin>
                                                </Panel>
                                            )
                                        })
                                    }
                                </Collapse>
                                :
                                <Empty/>
                        }

                    </Spin>
                </Card>
            </>

        )
    }
}
export default withRouter(PerformanceDetail)